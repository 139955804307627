.raw_logo {
  height: 305;
  width: 440;
}

svg .lets {
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  animation: animate 5s forwards linear;
}
@keyframes animate {
  to {
    stroke-dashoffset: 0;
  }
}
